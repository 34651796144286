<template>
  <v-main>
    <v-app>
      <v-app-bar
        color="rgba(255,255,255,0)"
        absolute
        dense
        flat
        :dark="dark"
        :light="!dark"
      >
        <v-app-bar-title font-size="0.4rem"
          >Medtronic Innovation Accelerator</v-app-bar-title
        >
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      </v-app-bar>
      <v-navigation-drawer
        v-model="drawer"
        absolute
        right
        color="transparent"
        dark
      >
        <!-- <v-list-item-group dark active-class="item_active">
        <v-list-item>
          <v-spacer></v-spacer>
          <v-icon @click="drawer = false">mdi-close</v-icon>
        </v-list-item>
        <v-list-item two-line to="/">
          <v-list-item-title>首页</v-list-item-title>
        </v-list-item>
        <v-divider color="rgba(1,73,131, 1)"></v-divider>
        <v-list-item two-line to="/i/about" tag="div">
          <v-list-item-title>创新加速器简介</v-list-item-title>
        </v-list-item>
        <v-divider color="rgba(1,73,131, 1)"></v-divider>
        <v-list-item two-line to="/i/kdAbout">
          <v-list-item-title>空岛科技简介</v-list-item-title>
        </v-list-item>
        <v-divider color="rgba(1,73,131, 1)"></v-divider>
        <v-list-item two-line to="/i/qa">
          <v-list-item-title>创业之路访谈</v-list-item-title>
        </v-list-item>
        <v-divider color="rgba(1,73,131, 1)"></v-divider>
        <v-list-item two-line to="/i/product">
          <v-list-item-title>创业企业产品展示</v-list-item-title>
        </v-list-item>
        <v-divider color="rgba(1,73,131, 1)"></v-divider>
        <v-list-item two-line to="/i/tacx">
          <v-list-item-title>美敦力CFO谈创新加速器</v-list-item-title>
        </v-list-item>
        <v-divider color="rgba(1,73,131, 1)"></v-divider>
        <v-list-item two-line to="/i/takd">
          <v-list-item-title>创新加速器领导谈空岛科技</v-list-item-title>
        </v-list-item>
        <v-divider color="rgba(1,73,131, 1)"></v-divider>
        <v-list-item
          two-line
          href="https://www.expoon.com/e/4m5jrmhwe6c/panorama?from=singlemessage&isappinstalled=0"
          color="rgba(7, 28, 67, 1)"
        >
          <v-list-item-title color="rgb(7, 28, 67, 1)"
            >美敦力 创新加速器VR展示</v-list-item-title
          >
        </v-list-item>
        <v-divider color="rgba(1,73,131, 1)"></v-divider>
      </v-list-item-group> -->

        <div class="navbar">
          <div class="item">
            <router-link to="/">首页</router-link>
          </div>
          <div class="item">
            <router-link to="/i/about">创新加速器简介</router-link>
          </div>
          <div class="item">
            <router-link to="/i/kdAbout">空岛科技简介</router-link>
          </div>
          <div class="item">
            <router-link to="/i/qa">创业之路访谈</router-link>
          </div>
          <div class="item">
            <router-link to="/i/product">创业企业产品展示</router-link>
          </div>
          <div class="item">
            <router-link to="/i/tacx">美敦力CFO谈创新加速器</router-link>
          </div>
          <div class="item">
            <router-link to="/i/takd">创新加速器领导谈空岛科技</router-link>
          </div>
          <div class="item">
            <a href="https://www.expoon.com/e/4m5jrmhwe6c/panorama?from=singlemessage&isappinstalled=0">美敦力 创新加速器VR展示</a>
          </div>
          <div class="close">
          <v-icon @click="drawer = false">mdi-close</v-icon>
          </div>
        </div>
      </v-navigation-drawer>
      <router-view @getMessage="updateDark"></router-view>
    </v-app>
  </v-main>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      drawer: false,
      dark: true,
    };
  },
  methods: {
    updateDark(dark) {
      this.dark = dark;
    },
  },
};
</script>

<style lang="less" scoped>
.navbar {
  height: 100%;
  width: 100%;
  color: #fff;
  font-size: 1.3rem;
  position: relative;

  .item {
    height: calc(100vh / 8);
    display: flex;
    align-items: center;
    padding: 0 2rem;

    &:nth-child(1) {
      background-color: rgba(111, 194, 233, .95);
    }
    &:nth-child(2) {
      background-color: rgba(0, 168, 226, .95);
    }
    &:nth-child(3) {
      background-color: rgba(0, 140, 216, .95);
    }
    &:nth-child(4) {
      background-color: rgba(0, 121, 196, .95);
    }
    &:nth-child(5) {
      background-color: rgba(0, 102, 182, .95);
    }
    &:nth-child(6) {
      background-color: rgba(0, 72, 139, .95);
    }
    &:nth-child(7) {
      background-color: rgba(1, 49, 109, .95);
    }
    &:nth-child(8) {
      background-color: rgba(7, 28, 67, .95);
    }
  }
  .close{
    position: absolute;
    right: 1rem;
    top:1rem;
    transform: rotate(90);
  }

  a {
    color: #fff;
  }
}
</style>
